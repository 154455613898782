.testimonialsTextDesktop {
  display: flex;
  text-align: center;
  align-items: center;
  color: #fff;
  font-family: Rajdhani;
  font-size: 37px;
  font-weight: 600;
  background: url(https://images.bikebiz.com.au/testimonials.svg);
  background-repeat: no-repeat;
  height: 128px;
  line-height: 26px;
  text-transform: uppercase;
  background-size: 159px;
}

.reviewSlides {
  display: flex;
  transition: all 0.3s ease 0s;
}

.owlNav {
  position: absolute;
  top: 54%;
  right: -24px;
  transform: translateY(-50%);
  z-index: 5;
  flex-direction: column;
  .owlPrev,
  .owlNext {
    cursor: pointer;
    color: #272c37;
    height: 74px;
    width: 74px;
    border: 1px solid #939393;
    transition: 0.3s;
    opacity: 1;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
    &.active {
      border-color: #ffb00f;
      background-color: #ffb00f;
      color: black;
    }
  }
  .owlNext {
    margin-top: 8px;
  }
}

@media (max-width: 1024px) {
  .testimonialsTextDesktop {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    font-size: 26px;
    font-weight: 600;
    background: url(https://images.bikebiz.com.au/testimonials.svg);
    background-repeat: no-repeat;
    background-position: 50%;
    height: 90px;
    line-height: 26px;
    text-transform: uppercase;
    background-size: 110px;
  }
}
