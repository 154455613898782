.main_group {
  height: 176px;
  align-items: flex-end;
  display: flex;
  background-position: center;
  background-size: cover;
  padding-bottom: 21.5px;
  // @media screen and (max-width: 600px) {
  //   background-position: -400px;
  // }
  @media screen and (min-width: 769px) {
    height: 384px;
    padding-bottom: 30px;
    &.main_group_sm {
      margin-top: 32px;
      height: 325px;
    }
  }
  transition: all 0.5s cubic-bezier(0, 0, 0.26, 1);
  &:hover {
    transform: scale(1.02);
  }
}
